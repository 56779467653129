import { useFetch, useRuntimeConfig } from 'nuxt/app'
import { fetchNotifications } from '~/components/notifications/toast/toast'
import { ToastStore } from '~/store/toastStore'
import { createToast } from '~/components/notifications/toast/toast'

import axios from 'axios'

interface ApiFunctionArgs {
  url?: string
  query?: Record<string, any>
  body?: Record<string, any>
  headers?: Record<string, any>
  imageFile?: string | Blob | undefined
}

const token = useCookie('token')
const config = useRuntimeConfig()
const baseUrl = config.public.env.NUXT_API_BASE_URL

export const checkNotifications = (flash: Headers) => {
  const headers = flash
  const headerActions: {
    [key: string]: { condition: (value: string) => boolean; action: () => void }
  } = {
    'Flash-Notifications': {
      condition: (value: string) => Number(value) > 0,
      action: () => {
        const isToast = ToastStore()
        if (!isToast.$state.createToast) {
          fetchNotifications()
          isToast.$state.createToast = true
        }
      },
    },
  }

  for (const headerName in headerActions) {
    const headerValue = headers.get(headerName)
    const { condition, action } = headerActions[headerName]
    if (headerValue && condition(headerValue)) {
      action()
    }
  }
}

export const apiGet = async ({ url, query, headers }: ApiFunctionArgs) => {
  try {
    const setUrl = baseUrl + '/' + url
    let queryStrings: string[] | string = ''
    if (query) {
      queryStrings = Object.entries(query).map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((item) => `${key}[]=${encodeURIComponent(item)}`).join('&')
        } else {
          return `${key}=${encodeURIComponent(value)}`
        }
      })
    }
    const queryString = queryStrings ? queryStrings.join('&') : ''
    const { data, error,status } = await useFetch(setUrl + (queryString ? `?${queryString}` : ''), {
      method: 'GET',
      headers: {
        Authorization: token.value ? `Bearer ${token.value}` : undefined,
        Accept: 'application/json',
        ...headers,
      },

      onResponse({ response, options }) {
        checkNotifications(response.headers)
      },
    })
    return { data, error,status }
  } catch (error: any) {
    console.error(error)
    const newErrorToast = new createToast({
      type: 'server_error',
      title: 'Ошибка',
      condition: 'toast-error',
    })
    newErrorToast.createNewToast(error.message)
    throw error
  }
}

export const apiPost = async ({ url, query, body, headers }: ApiFunctionArgs) => {
  const newErrorToast = new createToast({
    type: 'server_error',
    title: 'Ошибка',
    condition: 'toast-error',
  })
  try {
    let errors = null
    let status: number | undefined = undefined
    const setUrl = baseUrl + '/' + url
    let queryStrings: string[] = []
    if (query) {
      queryStrings = Object.entries(query).map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((item) => `${key}[]=${encodeURIComponent(item)}`).join('&')
        } else {
          return `${key}=${encodeURIComponent(value)}`
        }
      })
    }
    const queryString = queryStrings ? queryStrings.join('&') : ''
    const { data, error } = await useFetch(setUrl + (queryString ? `?${queryString}` : ''), {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        'Authorization': token.value ? `Bearer ${token.value}` : null,
      },
      body: JSON.stringify(body),
      onResponse({ response, options }) {
        checkNotifications(response.headers)
      },
      onResponseError({ response }) {
        errors = response._data
        status = response.status
        newErrorToast.createNewToast(response._data.message)
      },
    })

    return { data, errors, status }
  } catch (error: any) {
    newErrorToast.createNewToast(error.message)
  }
}

export const apiPostImage = async ({ url, imageFile, headers }: ApiFunctionArgs) => {
  try {
    const setUrl = baseUrl + '/' + url
    const formData = new FormData()

    if (imageFile !== undefined) {
      formData.append('image', imageFile)
    } else return
    const response = await axios.post(setUrl, formData, {
      headers: {
        ...headers,
        'Authorization': token.value ? `Bearer ${token.value}` : null,
        'Content-Type': 'multipart/form-data',
      },
    })
    const newCompleteToast = new createToast({
      type: 'server_error',
      title: 'Успешно',
      condition: 'toast-complete',
    })
    newCompleteToast.createNewToast(response.data.message || 'Изображение загружено успешно!')

    return response.data
  } catch (error: any) {
    console.error('Error:', error)
    if (error.response && error.response.status === 413) {
      const newErrorToast = new createToast({
        type: 'server_error',
        title: 'Ошибка',
        description: 'Максимальный размер файла 10 мб, формат: JPG или PNG',
        condition: 'toast-error',
      })
      newErrorToast.createNewToast()
    }

    throw error
  }
}

export const apiPatch = async ({ url, query, body, headers }: ApiFunctionArgs) => {
  try {
    const setUrl = baseUrl + '/' + url
    const queryParams = new URLSearchParams(query)

    const response = await useFetch(setUrl + (queryParams ? `?${queryParams.toString()}` : ''), {
      method: 'PATCH',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        'Authorization': token.value ? `Bearer ${token.value}` : null,
      },
      body: JSON.stringify(body),
      onResponse({ response, options }) {
        checkNotifications(response.headers)
      },
    })
    const data = response.data
    return {data}
  } catch (error: any) {
    console.error(error)
    const newErrorToast = new createToast({
      type: 'server_error',
      title: 'Ошибка',
      condition: 'toast-error',
    })
    newErrorToast.createNewToast(error.message)
    throw error
  }
}

export const apiDelete = async ({ url, body }: ApiFunctionArgs) => {
  try {
    const setUrl = baseUrl + '/' + url
    const { data, error, status } = await useFetch(setUrl, {
      method: 'DELETE',
      headers: {
        Authorization: token.value ? `Bearer ${token.value}` : null,
      },
      body: body,
      onResponseError({ response }) {
        if (response && response.status && response.status === 400) {
          const newErrorToast = new createToast({
            type: 'server_error',
            title: 'Ошибка',
            description: 'Дефолтную аватарку юзера нельзя удалить.',
            condition: 'toast-error',
          })
          newErrorToast.createNewToast()
        }
      },
    })
    return { data, error, status }
  } catch (error: any) {
    console.log(error)
    throw error
  }
}

export const apiPut = async ({ url, query, body, headers }: ApiFunctionArgs) => {
  const newErrorToast = new createToast({
    type: 'server_error',
    title: 'Ошибка',
    condition: 'toast-error',
  })
  try {
    const setUrl = baseUrl + '/' + url
    const queryParams = new URLSearchParams(query)

    const { data, error } = await useFetch(
      setUrl + (queryParams ? `?${queryParams.toString()}` : ''),
      {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': 'application/json',
          'Authorization': token.value ? `Bearer ${token.value}` : null,
        },
        body: JSON.stringify(body),
        onResponse({ response, options }) {
          checkNotifications(response.headers)
        },
        onResponseError({ response }) {
          newErrorToast.createNewToast(response._data.message)
        },
      },
    )

    return { data, error }
  } catch (error: any) {
    newErrorToast.createNewToast(error.message)
    throw error
  }
}
