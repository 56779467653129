import { defineStore } from 'pinia'
import { apiGet, apiPatch } from '~/services/api'

export interface Notification {
  created_at: string
  title: string
  description: string
  is_read: boolean
  id: string
}

export const ToastStore = defineStore('toastStore', {
  state: () => ({
    isBadge: false,
    createToast: false,
    ShowlistNotifications: null,
    allNotifications: [] as Notification[],
    notifyDisplay: {
      currentPage: 0,
      totalPages: 0,
    },
  }),
  actions: {
    async fetchListNotification() {
      try {
        const { data } = await apiGet({ url: 'api/notification/feed' })
        if (data) {
          this.allNotifications = (data as any).value.data
          this.notifyDisplay.currentPage = (data as any).value.meta.current_page
          this.notifyDisplay.totalPages = (data as any).value.meta.last_page
          if (this.allNotifications.some((notification) => !notification.is_read)) {
            this.isBadge = true
          } else this.isBadge = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    async fetchNextPageNotification() {
      try {
        const { data } = await apiGet({
          url: 'api/notification/feed',
          query: {
            page: this.notifyDisplay.currentPage,
          },
        })
        if (data) {
          this.allNotifications = [...this.allNotifications, ...(data as any).value.data]
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.notifyDisplay.currentPage = this.notifyDisplay.currentPage + 1
      }
    },

    async makeRead(id: string) {
      try {
        await apiPatch({ url: `api/notification/mark-as-read/${id}` })
        this.allNotifications.forEach((notify) => {
          if (notify.id === id) {
            notify.is_read = true
          }
        })
      } catch (e) {
        console.log(e)
      } finally {
        if (this.allNotifications.some((notification) => !notification.is_read)) {
          this.isBadge = true
        } else this.isBadge = false
      }
    },
  },
})
